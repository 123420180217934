.alloc-q-filters-block {
  display: flex;
  align-items: center;

  h2 {
    font-family: 'GothamRegular', sans-serif;

    font-weight: 450;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: @dart-dark;
    margin-top: 32px;
    margin-bottom: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  &.open {
    .bottom {
      min-height: 360px;
      height: fit-content;
      padding-bottom: 32px;
      opacity: 1;
      transition: 0.3s;
    }
  }

  .container-content {
    width: 100%;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;

    .left {
      display: flex;
      column-gap: 16px;
      align-items: center;

      .ant-btn {
        display: flex;
        column-gap: 18px;
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        border: none;
        outline: none;
        box-shadow: none;
        height: 24px;
        padding: 3px;
        box-sizing: content-box;

        .dart-icon {
          margin-top: 1px;
        }
      }

      .vertical-splitter {
        width: 1px;
        height: 16px;
        background-color: @dart-gray;
      }

      .filtered-store-counter {
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        color: @dart-mid-gray;
      }
    }

    .right {
      display: flex;

      .discard-changes-button {
        .ant-btn {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 16px;
          line-height: 24px;
          text-decoration-line: underline;
          color: @dart-dark;
          background-color: transparent;
          text-transform: initial;
          box-shadow: none;
          width: 132px;
          padding: 0 15px;
          box-sizing: content-box;
          margin-right: 16px;
        }
      }
    }
  }

  .close-button {
    outline: none;
    border: none;
    box-shadow: none;
  }

  .bottom {
    height: 0;
    opacity: 0;
    transition: 0.3s;
    padding-bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .flex-column-2{
      display: flex;
      justify-content: space-between;
      column-gap: 32px;
      row-gap: 32px;
      flex-wrap: wrap;
      > .bottom-left {
        flex: 1;
      }
      > .bottom-right {
        width: 220px;
      }
    }

    .search-input-wrapper {
      min-width: 300px;
      .dart-input .dart-input-target {
        background-color: white !important;
        border: 2px solid #C3C5C8;
      }
      .ant-input-suffix{
        padding-left: 12px;
        height: 28px;
        margin-top: 4px;
        *{
          fill: @dart-dark;
        }
      }
    }

    .sort-input-wrapper,
    .filter-input-wrapper {
      display: flex;
      column-gap: 32px;
      flex-wrap: wrap;

      .dart-select {
        .ant-select-selector {
          line-height: 48px;
          height: 48px;

          input {
            height: 48px;
          }
        }

        .ant-select-selection-item {
          line-height: 48px;
        }

        .ant-select-selection-placeholder {
          line-height: 48px;
        }
      }

      .dart-select-label,
      .label {
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }

      .checkbox-group-wrap {
        margin-top: 4px;
        display: flex;
        column-gap: 32px;

        .checkbox-title {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  }

  .filters-splitter {
    margin: 32px 0;
    border: none;
    border-bottom: 1px solid @dart-gray-alt;
  }
  .button-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1028px;
    width: 100%;
    column-gap: 12px;
  }
  .apply-filters-button {
    flex: 12;
    .ant-btn {
      padding: 16px 32px;
      max-width: 980px;
      width: 100%;
      height: 48px;
      background: @dart-dark !important;
      border-radius: 8px;
      margin: 0 auto;
    }
  }
  .reset-filters-button{
    flex: 1;
    .ant-btn {
      padding: 16px 0px;
      max-width: 80px;
      width: 100%;
      height: 48px;
      background: #F6F6F6 !important;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      .dart-button-content{
        span:nth-child(2){
          display: none;
        }
      }
      *{
        fill: @dart-dark;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";