.flow-doc-preview-wrapper {
  .top{
    margin-top: 72px;
  }
  .bottom{
    margin-top: 32px;
  }
  .BaseTable__header-cell-text{
    .circle-dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 24px;
    }
  }
  .guide-wrapper {
    display: flex;
    column-gap: 12px;
    .circle-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 3px;
    }
    p{
      display: inline-block;
      font-family: GothamLight, sans-serif;
      font-size: 12px;
      margin: 0;
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";