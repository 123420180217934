.store-tags{
  margin-top: 40px;
  margin-bottom: 64px;
  position: relative;
  &.disabled{
    &:before{
      content: "";
      height: calc(100% - 40px);
      width: 100%;
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 1;
      backdrop-filter: blur(2px);
      background-color: transparent;
      cursor: not-allowed;
    }
  }

  h2, h3{
    font-family: 'GothamRegular', sans-serif;

    font-weight: 450;
    text-transform: uppercase;
    color: @dart-dark;
    margin-top: 0;
  }

  h2{
    font-size: 20px;
    line-height: 16px;
    height: 16px;
    margin-bottom: 40px;
    span{
      font-size: 11px;
      text-align: right;
      float: right;
      color: @dart-secondary;
    }
  }

  .tags-wrapper{
    border-radius: 16px;
    background-color: @dart-light;
    padding: 12px 24px 24px 24px;
    transition: 0.3s;
    position: relative;
    .tag-counter {
      position: absolute;
      text-align: left;
      display: flex;
      align-items: center;
      float: left;
      column-gap: 0;
      line-height: 30px;
      font-family: 'GothamLight', sans-serif;
    }
    .tag-header{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: -12px;
      .ant-btn {
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 12px;
        font-family: 'GothamLight', sans-serif;

        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        border: none !important;
        box-shadow: none !important;
        &:nth-child(2){
          .anticon{
            margin-top: 4px;
          }
        }
      }
    }
    .tag-container-splitter {
      margin-top: 12px;
      margin-bottom: 16px;
      border: 0;
      border-bottom: 1px solid #C3C5C8;
    }
    .new-tag-wrapper{
      margin-bottom: 16px;
      .dart-input.transparent{
        background-color: white;
        border-radius: 8px;
      }
      &.has-error{
        .dart-input-target{
          border: 2px solid @dart-danger !important;
        }
      }
    }

    h3{
      font-size: 12px;
      line-height: 12px;
      height: 12px;
      margin-bottom: 16px;
    }

    .tag-container{
      display: flex;
      column-gap: 8px;
      row-gap: 8px;
      flex-wrap: wrap;
      .tag{
        border-radius: 24px;
        min-width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        font-family: 'GothamLight', sans-serif;

        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        color: @dart-dark;
        background-color: white;
        border: 1px solid @dart-gray-alt;
        user-select: none;
        cursor: pointer;

        /* Secondary/Green */
        &.selected {
          border: 1px solid @dart-secondary;
          color: @dart-secondary;
        }
        &.deleting {
          padding-right: 2px;
          border: 1px solid @dart-danger;
          color: @dart-dark;
        }
        .tag-text{
          display: flex;
          align-items: center;
        }
        .ant-btn{
          border: none !important;
          box-shadow: none !important;
          --antd-wave-shadow-color: @dart-primary;
          .anticon{
            margin-top: -12px;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";