.size-run-layout {
  max-width: 603px;
  margin: 0 auto;
  padding-bottom: 64px;
  .ant-form-item-label{
    display: block;
    ::before,
    :before,
    :after{
      display: none !important;
    }
  }
  .splitter {
    border: none;
    border-bottom: 1px solid @dart-gray;
    margin: 0;
  }
}

.size-run-layout-wrap{
  max-height: calc(100vh - 64px);
  overflow-x: hidden;
  overflow-y: auto;
}

// used for top header
.main-layout-content {
  .size-run-layout-wrap{
    padding: 0 64px;
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";