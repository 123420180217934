.alloc-q-dashboard-header {
  .dart-page-title{
    height: 100px;
    margin-top: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .center{
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: @dart-dark;
    }

    .right {
      position: initial;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }

    .filters {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
      text-transform: initial;

      .ant-form-horizontal{
        display: flex;
        flex-direction: row;
        .ant-form-item{
          margin-bottom: 0;
        }
        .vertical-separator{
          height: 16px;
          width: 1px;
          background-color: #C3C5C8;
          margin-top: 6px;
          margin-left: 16px;
          margin-right: 16px;
        }
      }

      .dart-icon {
        margin-top: -2px;
      }
    }
  }
}

// used for top header
.main-layout-content {
  .alloc-q-dashboard-header{
    .dart-page-title{
      background-color: @dart-light;
      position: fixed;
      width: 100vw;
      top: 94px;
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";