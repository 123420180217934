.store-list-view{
  .store-list-status-selector{
    margin-bottom: 58px;
    display: flex;
    justify-content: space-between;
    max-width: 888px;
    .dart-pill-select {
      .pill {
        --antd-wave-shadow-color: @dart-dark;
        &.selected {
          background-color: @dart-dark;
          color: white;
        }
        &.disabled {
          background-color: @dart-light;
          color: @dart-gray;
        }
      }
    }
    .tag-select-wrapper{
      .ant-select{
        cursor: pointer;
        width: 300px;
      }
      .ant-select-selector{
        width: 100%;
        background-color: @dart-light !important;
      }
      .ant-select-arrow{
        position: absolute;
        right: 11px;
        top: 9px;
      }
      .ant-select-selection-overflow-item{
        padding-left: 4px;
      }
      .ant-select-selection-placeholder{
        position: absolute;
        top: 4px;
        left: 14px;
      }
    }
  }
  .store-list-wrapper{
    max-width: 888px;
    .ant-spin{
      position: absolute;
      left: 0;
      right: 0;
      top: 250px;
      max-width: 888px;
    }
    .store-list-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .revalidate-spin {
        position: absolute;
        top: 8px;
      }
      .left{
        display: flex;
        column-gap: 12px;
        .ant-btn{
          border: none;
          display: flex;
          column-gap: 12px;
          box-shadow: none;
          padding-left: 6px;
          padding-right: 6px;
          color: black;
        }
      }
      .right{
        display: flex;
        column-gap: 16px;
        .ant-btn{
          display: flex;
          column-gap: 8px;
          padding: 8px;
          border-radius: 8px;
          border: 1px solid #E1E2E3;
          height: fit-content;
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
        .icon-wrapper{
          width: 24px;
          height: 24px;
          background: #E87324;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    > hr {
      margin: 16px 0 24px 0;
      border: 0;
      border-bottom: 1px solid @dart-gray-alt;
    }
    .store-list{
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      height: calc(100vh - 230px);
      overflow-y: scroll;
      width: calc(100% + 29px);
      padding-right: 29px;
      position: relative;
      .right{
        .ant-btn{
          display: flex;
          column-gap: 10px;
          align-items: center;
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          border: none;
          box-shadow: none;
          margin-top: -8px;

          .dart-icon{
            display: flex;
            color: @dart-dark;
          }
        }
      }
    }
  }
}

.main-layout-content {
  .store-list-view{
    .store-list-wrapper{
      .store-list{
        height: calc(100vh - 300px);
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";