.dart-page-title-search{
  padding-right: 64px;
  .ant-input{
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: #333E47;
    background: white !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 200px;
    border-color: #E87324 !important;
    box-shadow: none !important;
  }
  .ant-input-group-addon{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    button {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      height: 34px;
      box-shadow: none;
      background-color: #E87324;
      border-color: #E87324 !important;
      svg * {
        fill: white;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";