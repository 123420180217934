.dart-segmented-select {
  * {
    user-select: none;
  }
  &.large {
    .ant-segmented-item-label{
      height: 36px;
      min-height: 36px;
      line-height: 36px;
    }
  }
  &.middle {
    .ant-segmented-item-label{
      height: 32px;
      min-height: 32px;
      line-height: 32px;
    }
  }
  &.small {
    .ant-segmented-item-label{
      height: 28px;
      min-height: 28px;
      line-height: 28px;
    }
  }
  .ant-segmented{
    width: 100%;
    background: transparent !important;
    box-shadow: none;
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 12px;
    line-height: 18px;
    color: #000000;

    .ant-segmented-item {
      z-index: 1;
    }

    .ant-segmented-item-selected {
      background-color: @dart-dark;
      color: white;
      z-index: 2;
      transition: color 0s;
      transition-delay: 0s;
    }

    .ant-segmented-thumb {
      background-color: @dart-dark;
      border-radius: 8px !important;
      z-index: 3;
    }

    .ant-segmented-group{
      column-gap: 8px;
      .ant-segmented-item{
        border-radius: 8px !important;
        flex: 1;
        &:not(.ant-segmented-item-selected){
          .ant-segmented-item-label{
            background: @dart-light;
            color: black;
            border-radius: 8px !important;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";