.custom-category-poa {
  .ant-modal-header {
    display: none;
  }

  &.dart-prompt .ant-modal-content .ant-modal-body {
    padding-top: 8px;
    padding-bottom: 32px;
  }

  &.dart-prompt .ant-modal-footer .ant-btn.ant-btn-primary {
    background-color: @dart-primary;
    &.disabled{
      background-color: #959A9D;
    }
  }
}

.render-custom-category-poa{
  h2{
    font-family: 'GothamRegular', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: @dart-dark;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .group-statistic-info{
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: @dart-dark;
    padding: 16px 0;
  }
  .group-statistic-info + .splitter{
    margin-top: 0;
  }
  .dart-input-label{
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: left;
    margin-left: -6px;
    margin-bottom: 8px;
  }
  .dart-select {
    margin-bottom: 12px;
    > label {
      text-align: left;
      padding-left: 0;
    }
    .ant-select{
      width: 100%;
      .ant-select-selector{
        width: 100% !important;
      }
    }
  }
  .search-results-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .search-result-single{
      background: #F6F6F6;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      &:first-child{
        margin-top: 16px;
      }
      .search-result-name{
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .search-result-action{
        font-family: 'GothamLight', sans-serif;
        font-style: normal;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        .green{
          color: @dart-secondary;
          cursor: not-allowed;
        }
        .orange{
          color: @dart-primary;
          cursor: pointer;
        }

      }
    }
  }
  .selected-styles-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .selected-style-single{
      background: transparent;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .selected-style-name{
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }
  .dart-input.error{
    .ant-input{
      border-color: @dart-danger;
    }
  }
  .error-message{
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 12px;
    line-height: 16px;
    color: @dart-danger;
    margin-top: 4px;
    text-align: left;
  }
}

.custom-category-poa-modal{
  .ant-modal-body{
    padding-bottom: 16px !important;
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";