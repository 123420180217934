.dart-collapse-checkbox-group {
  .title{
    font-family: 'GothamBlack', sans-serif;
    font-weight: 450;
    font-size: 12px;
    line-height: 12px;
    color: @dart-dark;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    height: 24px;
    margin: 16px 0;
    align-items: center;
    padding-right: 6px;
    user-select: none;
    .right{
      .anticon{
        *{
          fill: @dart-mid-gray;
          font-size: 14px;
        }
      }
    }
  }
  .body{
    display: flex;
    row-gap: 12px;
    flex-direction: column;
    overflow: hidden;
    transition: 0.3s;
    &.hidden{
      height: 0;
      opacity: 0;
      margin-top: -16px;
    }
    &.visible{
      height: fit-content;
      margin-top: 0;
      opacity: 1;
    }
    .dart-checkbox{
      .ant-checkbox-inner{
        width: 20px;
        height: 20px;
      }
      &.sm .checkbox-title{
        user-select: none;
        font-family: 'GothamLight', sans-serif !important;

        font-weight: 325;
        font-size: 12px !important;
        line-height: 18px !important;
        color: @dart-dark;
        padding-left: 8px;
        cursor: pointer;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";