.allocation-history-list-block {
  padding-bottom: 40px;

  hr {
    border: 0;
    height: 1px;
    background-color: @dart-mid-gray;
    opacity: 0.3;
    width: 96%;
    margin: 24px auto 0 auto;
  }

  .allocation-history-list-item {
    border-radius: 16px;
    background-color: #F6F6F6;
    padding: 16px;
    position: relative;
    box-shadow: 0 0 11px rgba(33, 33, 33, 0);
    transition: 0.2s;

    * {
      user-select: none;
    }

    &.group-holder {
      width: 100%;

      &:hover {
        cursor: s-resize;
      }

      &.open {
        &:hover {
          cursor: n-resize;
        }

        background-color: #E8E8E8;
      }
    }

    .top {
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      .time-ago-and-type {
        font-family: 'GothamRegular', sans-serif;
        font-weight: 450;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: #333E47;

        .status-badge {
          transform: translateY(-2px);
          margin-left: 8px;
          margin-right: 0;
          color: white;
          font-size: 12px;
          text-transform: capitalize;
          border-radius: 16px;
          border: none;
          font-family: "GothamBold", sans-serif;
          line-height: 18px;
        }
      }
    }

    .middle {
      .warehouses {
        display: flex;
        margin-top: 10px;
        margin-bottom: -10px;
        flex-wrap: wrap;
      }

      .status-badge {
        transform: translateY(-2px);
        color: white;
        font-size: 12px;
        text-transform: capitalize;
        border-radius: 16px;
        border: none;
        font-family: "GothamBold", sans-serif;
        line-height: 18px;
      }
    }

    .bottom {
      margin-top: 24px;
      display: flex;
      column-gap: 4px;
      align-items: baseline;

      .count {
        font-family: 'GothamRegular', sans-serif;
        font-weight: 450;
        font-size: 32px;
        line-height: 24px;
        text-transform: uppercase;
        color: #333E47;
      }

      .rest {
        font-family: 'GothamLight', sans-serif;
        font-weight: 450;
        font-size: 14px;
        line-height: 12px;
        color: #333E47;
      }
    }

    .details-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      height: 100%;
      width: 60px;
      top: 0;
      outline: none;
      border: none;

      * {
        transition: fill 0.2s;
      }
    }

    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, .2);
      transition: 0.2s;
      cursor: pointer;

      .details-button {
        * {
          transition: fill 0.2s;
          fill: @dart-primary;
        }
      }
    }
  }

  .history-empty {
    width: 100%;
    height: 141px;
    background-color: @dart-light;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 18px;
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";