.user-list-cards{
  padding: 0 64px;
  .user-list-cards-role-selector{
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
    .dart-pill-select {
      .pill {
        --antd-wave-shadow-color: @dart-dark;
        &.selected {
          background-color: @dart-dark;
          color: white;
        }
        &.disabled {
          background-color: @dart-light;
          color: @dart-gray;
        }
      }
    }
    .tag-select-wrapper{
      .ant-select{
        cursor: pointer;
        width: 300px;
      }
      .ant-select-selector{
        width: 100%;
        background-color: @dart-light !important;
      }
      .ant-select-arrow{
        position: absolute;
        right: 11px;
        top: 9px;
      }
      .ant-select-selection-overflow-item{
        padding-left: 4px;
      }
      .ant-select-selection-placeholder{
        position: absolute;
        top: 4px;
        left: 14px;
      }
    }
  }
  .user-list-content-wrapper{
    position: relative;
    .ant-spin{
      position: absolute;
      left: 0;
      right: 0;
      top: 250px;
    }

    > hr {
      margin: 0 0 24px 0;
      border: 0;
      border-bottom: 1px solid @dart-gray-alt;
    }
    .user-list{
      display: flex;
      flex-wrap: wrap;
      column-gap: 32px;
      row-gap: 32px;
      justify-content: center;
      padding-bottom: 32px;
    }
    .user-list-empty{
      width: 100%;
      max-width: 500px;
      height: 160px;
      background-color: @dart-light;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 18px;
      font-family: 'GothamLight', sans-serif;

      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}

.main-layout-content {
  .user-list-cards{

  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";