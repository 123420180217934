.dart-drag-select{
  * {
    color: @dart-dark !important;
    font-family: 'GothamLight', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
.dart-drag-select__control{
  background: #F6F6F6 !important;
  border-radius: 8px !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}
.dart-drag-select__multi-value {
  z-index: 2001 !important;
  background-color: @dart-dark !important;
  border-radius: 8px !important;
  * {
    color: white !important;
  }
}
.dart-drag-select__menu {
  z-index: 2001 !important;
}
.dart-drag-select__placeholder {
  text-align: left;
}
.dart-drag-select__multi-value__label{
  cursor: all-scroll;
  padding: 3px 6px 3px 9px !important;
}
.dart-drag-select__multi-value__remove{
  cursor: pointer;
  transition: 0.1s;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  &:hover{
    background-color: @dart-danger !important;
    transition: 0.1s;
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";