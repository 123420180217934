.dart-compact-select-dropdown{
  width: 220px !important;
  .dropdown-search{
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dart-input.transparent .dart-input-target{
      height: 32px;
    }
  }
  .dropdown-actions{
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #f5f5f5;
    column-gap: 16px;
    button.ant-btn,
    button.ant-btn:focus,
    button.ant-btn:focus-within,
    button.ant-btn:active{
      &[ant-click-animating-without-extra-node='true']::after{margin-top:4px;}
      border: none;
      outline: none;
      box-shadow: none;
      height: 24px;
      padding: 2px 4px;
      line-height: 24px;
      color: @dart-dark;
      span {
        text-decoration: underline;
      }
    }
  }
}
.dart-compact-select {
  position: relative;
  user-select: none;
  &.variable-width{
    .ant-select {
      display: flex;
      column-gap: 2px;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      .ant-select-selection-placeholder{
        display: block;
        position: initial;
        transform: none;
        width: fit-content;
        white-space: nowrap;
        overflow: visible;
        padding-right: 7px;
      }
      .ant-select-selection-item{
        padding: 0;
      }
      .ant-select-selector {
        padding-right: 20px;
      }

      .ant-select-arrow {
        margin-left: -20px;
        display: inline-block;
        position: initial;
        margin-top: 0;
      }
    }
  }
  .ant-select-dropdown {
    width: 220px !important;
  }

  .ant-select-selector{
    background-color: transparent !important;
  }

  &.no-border {
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .ant-select,
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: @dart-dark;
    font-family: 'GothamLight', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    .ant-select-selector {
      border: 0;
    }
  }

  .ant-select-arrow {
    transform: rotateZ(0deg);
    transition: 0.2s;

    .anticon * {
      fill: @dart-dark;
    }
  }

  .ant-select-open {
    .ant-select-arrow {
      transform: rotateZ(180deg);
      transition: 0.2s;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: fade(@dart-primary, 8%);
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) {
    .ant-select-arrow {
      * {
        fill: @dart-mid-gray
      }
    }

    .ant-select-selector {
      background-color: transparent;

      .ant-select-selection-placeholder {
        color: @dart-mid-gray;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";