.store-profile-details{
  margin-bottom: 40px;

  .store-profile-details-top-form{
    margin-bottom: 40px;
  }

  .store-profile-details-bottom-form{
    margin-top: 40px;
  }

  .ant-form-item-row{
    flex-direction: column;
  }

  h2, h3{
    font-family: 'GothamRegular', sans-serif;

    font-weight: 450;
    text-transform: uppercase;
    color: @dart-dark;
    margin-bottom: 24px;
    margin-top: 0;
  }
  h2{
    font-size: 20px;
    line-height: 16px;
    height: 16px;
  }
  h3{
    font-size: 16px;
    line-height: 20px;
    height: 20px;
  }
  hr.light-splitter{
    border: none;
    border-bottom: 1px solid @dart-gray-alt;
    margin: 8px 0 34px;
  }
  .dart-segmented-select .ant-segmented .ant-segmented-group .ant-segmented-item{
    flex: unset
  }
  .ant-form-item-label{
    display: block;
    text-align: left;
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-bottom: 8px;
    label{
      height: 16px;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
  .select-with-lock{
    position: relative;
    margin-bottom: 24px;
    .ant-form-item-has-error{
      margin-bottom: -24px !important;
    }
  }
  .ant-radio-inner::after {
    background-color: @dart-secondary;
  }
  .ant-radio-checked .ant-radio-inner{
    border: 4px solid @dart-secondary;
  }
  .ant-radio-checked::after{
    border: 4px solid @dart-secondary;
  }
  .ant-radio-group-large{
    display: flex;
    width: fit-content;
  }
  .ant-radio-wrapper{
    display: flex;
    flex: 1;
    align-items: center;
    * {
      box-shadow: none !important;
      outline: none !important;
    }
  }
  .ant-radio-inner{
    border: 2px solid @dart-gray;
    height: 32px;
    width: 32px;
  }
  span.ant-radio{
    margin-top: -8px;
    + span {
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(1);
  }
  span.ant-radio + *{
    padding-left: 16px;
    padding-right: 16px;
    line-height: 32px;
    white-space: nowrap;
  }
  .dart-select .ant-select .ant-select-selector, .dart-select .ant-select-selection-placeholder .ant-select-selector, .dart-select .ant-select-selection-item .ant-select-selector{
    width: 100% !important;
    height: 48px;
    border-radius: 8px;
  }
  .dart-select .ant-select-selection-item{
    height: 48px;
    line-height: 48px;
  }
  .dart-select .ant-select-selector{
    padding: 0 20px;
  }
  .ant-input-affix-wrapper{
    padding: 0 20px 0 0;
  }
  .dart-select .ant-select-arrow{
    right: 24px;
  }
  .dart-select .ant-select-clear{
    right: 24px;
  }
  .ant-col .ant-form-item:last-child{
    margin-bottom: 0;
  }
  .ant-select-disabled {
    .ant-select-selector {
      background-color: @dart-light !important;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .select-disabled-lock{
    position: absolute;
    right: 20px;
    top: 38px;
    cursor: not-allowed;
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";