.review-ccs-page {
  .dart-page-title{
    .center{
      .dart-icon{
        margin-top: -4px;
      }
    }
  }
}

// used for top header
.main-layout-content {
  .review-ccs-page{
    .dart-page-title {
      padding: 0 64px;
      .right{
        right: 64px;
      }
    }
    .review-cc-filters {
      padding: 0 64px;
    }
    .review-ccs-wrapper {
      padding: 0 64px;
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";