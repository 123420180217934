.newness-by-month {
  position: relative;
  .month-switcher{
    position: absolute;
    right: 0;
    display: flex;
    column-gap: 2px;
    z-index: 1;
    .ant-btn{
      outline: none;
      border: none;
      box-shadow: none;
      &[disabled]{
        .dart-icon {
          * {
            fill: @dart-mid-gray !important;
          }
        }
      }
      &:after {
        --antd-wave-shadow-color: @dart-dark;
      }
      &:first-child{
        .dart-icon{
          transform: rotateZ(90deg);
          margin-left: -3px;
          *{
            fill: @dart-dark;
          }
        }
      }
      &:last-child{
        .dart-icon{
          transform: rotateZ(-90deg);
          margin-left: 3px;
          *{
            fill: @dart-dark;
          }
        }
      }
    }
  }
  .skeleton-wrapper-col{
    .ant-skeleton{
      width: 100%;
      .ant-skeleton-input {
        height: 32px;
        width: 100%;
        max-width: 285px;
        margin-bottom: 20px;
        border-radius: 20px;
        &.month {
          max-width: 160px;
        }
        &.percent {
          max-width: 190px;
        }
        &.date {
          max-width: 180px;
          height: 18px;
          margin-bottom: 28px;
        }
        &.ccs {
          height: 143px;
          margin-bottom: 36px;
        }
        &.detail {
          max-width: 130px;
          height: 10px;
          margin-bottom: 10px;
        }
      }
      .ant-skeleton-avatar{
        width: 100%;
        max-width: 285px;
        height: 400px;
        border-radius: 20px;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";