.newness-by-month {
  .single-month-block{
    &.newness-by-month-col-wrapper{
      max-width: 285px;
      .last-updated{
        margin-top: 20px;
        margin-bottom: 28px;
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        color: @dart-mid-gray;
      }
      .month-cc-stats{
        background-color: @dart-light;
        border-radius: @dart-border-radius-lg;
        padding: 33px 6px 36px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 10px;
        margin-bottom: 36px;
        > div {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;
          column-gap: 10px;
          h1{
            font-family: 'GothamBold', sans-serif;
            font-weight: 800;
            font-size: 24px;
            line-height: 24px;
            text-transform: uppercase;
            color: @dart-mid-gray;
            margin-bottom: 0;
            margin-left: 11px;
            &:first-child{
              color: @dart-primary;
              margin-left: 0;
              margin-bottom: 26px;
            }
          }
          span {
            font-family: 'GothamLight', sans-serif;
            font-weight: 325;
            font-size: 12px;
            line-height: 18px;
            color: @dart-dark;
            &:first-child{
              margin-bottom: 32px;
              margin-top: 6px;
            }
          }
          &:last-child{
            align-items: flex-start;
          }
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";