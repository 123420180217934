.alloc-q-card-view {
  padding-top: 40px;
  padding-bottom: 40px;
  .loader{
    margin: 0 auto;
  }
  .empty{
    width: 100%;
    height: 200px;
    background: #F6F6F6;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";