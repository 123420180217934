.store-list-filters{
  padding-right: max(64px, 20%);
  hr{
    border: 0;
    height: 1px;
    border-bottom: 1px solid #E1E2E3;
    margin-top: 16px;
  }
  .applied-filters{
    h2{
      font-family: 'GothamBlack', sans-serif;
      font-weight: 450;
      font-size: 12px;
      line-height: 12px;
      color: @dart-dark;
      text-transform: uppercase;
    }
    .ant-btn{
      border: none;
      display: flex;
      column-gap: 12px;
      box-shadow: none;
      padding-left: 6px;
      padding-right: 6px;
      color: black;
      margin-left: -6px;
      margin-bottom: -10px;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      align-items: center;
      background: transparent !important;
      .anticon{
        *{
          fill: @dart-mid-gray;
          font-size: 20px;
        }
      }
    }
    .filters{
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 8px;
      &.empty{
        margin-top: 0;
        margin-bottom: 0;
      }
      .ant-tag{
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: @dart-secondary;
        background: transparent;
        border: 1px solid @dart-secondary;
        border-radius: 16px;
        padding: 7px 12px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .anticon{
          margin-left: 6px;
        }
        .anticon *{
          fill: @dart-secondary;
          font-size: 12px;
        }
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";