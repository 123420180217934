.dart-select {
  position: relative;
  user-select: none;
  .dart-select-label{
    font-family: 'GothamLight', sans-serif;

    font-weight: 325;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-left: 8px;
    display: block;
    height: 24px;
  }
  .ant-select-dropdown {
    width: 220px !important;
  }

  &.no-border {
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: transparent !important;
    box-shadow: 0 0 0 2px fade(@dart-primary, 20%) !important;
  }

  .ant-select,
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: @dart-dark;
    font-family: 'GothamLight', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    .ant-select-selector {
      border: 0;
      width: 220px !important;
      background: #F6F6F6 !important;
      border-radius: 8px;
    }
  }

  .ant-select-arrow {
    transform: rotateZ(0deg);
    transition: 0.2s;

    .anticon * {
      fill: @dart-dark;
    }
  }

  .ant-select-open {
    .ant-select-arrow {
      transform: rotateZ(180deg);
      transition: 0.2s;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: fade(@dart-primary, 8%);
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) {
    .ant-select-arrow {
      * {
        fill: @dart-mid-gray
      }
    }

    .ant-select-selector {
      background-color: transparent;

      .ant-select-selection-placeholder {
        color: @dart-mid-gray;
      }
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";