.selected-style-color-tab-head {
    .tab-top-card-title,
    .tab-top-card-subtitle {
      font-family: 'GothamLight', sans-serif;
      font-style: normal;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: @dart-dark;
      user-select: none;
    }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";