.page-summary-widget{
  .title{
    font-family: 'GothamRegular', sans-serif;
    font-weight: 450;
    font-size: 20px;
    line-height: 16px;
    text-transform: uppercase;
    color: @dart-dark;
    span{
      position: relative;
    }
    .loader{
      display: block;
      position: absolute;
      right: -32px;
      top: 2px;
    }
  }
  hr{
    border: 0;
    border-bottom: 1px solid #E1E2E3;
    margin: 16px 0;
  }
  .page-summary-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    .left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 32px;
      flex: 5;
    }
    .right{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .action-button-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        height: 84px;
        .action-button{
          margin-top: 2px;
          display: flex;
          column-gap: 12px;
          align-items: center;
          .dart-button.lg .ant-btn{
            padding: 16px 32px;
          }
        }
        .action-button-subtext{
          margin-top: 16px;
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          text-align: right;
          white-space: nowrap;
        }
      }
    }
  }
  .page-summary-extra-content-wrapper{
    display: flex;
    width: 100%;
    flex-direction: row;
  }
  .page-summary-content {
    height: 84px;
    background: @dart-light;
    border-radius: 16px;
    padding: 16px;
    overflow: hidden;

    .name {
      font-family: 'GothamBold', sans-serif;
      font-weight: 450;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: @dart-dark;
      margin-bottom: 8px;
    }

    .value {
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 24px;
      line-height: 32px;
      color: @dart-dark;
      &.long{
        font-size: 16px;
      }
    }
  }

}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";