@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

@font-face {
  font-family: 'GothamThin';
  src: url(./assets/fonts/GothamThin.otf) format('opentype');
}

@font-face {
  font-family: 'GothamLight';
  src: url(./assets/fonts/GothamLight.otf) format('opentype');
}

@font-face {
  font-family: 'GothamRegular';
  src: url(./assets/fonts/GothamRegular.otf) format('opentype');
}

@font-face {
  font-family: 'GothamBold';
  src: url(./assets/fonts/GothamBold.otf) format('opentype');
}

@font-face {
  font-family: 'GothamBlack';
  src: url(./assets/fonts/GothamBlack.otf) format('opentype');
}

@font-face {
  font-family: 'GothamMedium';
  src: url(./assets/fonts/GothamMedium.otf) format('opentype');
}

@font-face {
  font-family: 'GothamBook';
  src: url(./assets/fonts/GothamBook.otf) format('opentype');
}

@font-face {
  font-family: 'GothamUltra';
  src: url(./assets/fonts/GothamUltra.otf) format('opentype');
}

html {
  --antd-wave-shadow-color: @dart-primary;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: "GothamLight", 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin {
  .ant-spin-dot-item {
    background-color: #E87324;
  }
}

.ant-layout {
  background-color: transparent;
}

.side-menu-wrapper {
  padding: 20px 8px;
  background-color: white;
  height: 100vh;
}

.top-menu-wrapper{
  height: 64px;
}

.main-boxed-content {
  padding-left: 114px;
  padding-right: 62px;
}

.main-layout-content {
  height: calc(100vh - 94px);
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.cursor-pointer{
  cursor: pointer;
}

.cursor-not-allowed{
  cursor: not-allowed !important;
}

.d-flex{
  display: flex;
}

.center{
  justify-content: center;
  align-items: center;
}

.flex-start {
  justify-content: flex-start;
  align-items: center;
}

.flex-end{
  justify-content: flex-end;
  align-items: center;
}

.gap-6{
  column-gap: 6px;
  row-gap: 6px;
}
.gap-8{
  column-gap: 8px;
  row-gap: 8px;
}
.gap-12{
  column-gap: 12px;
  row-gap: 12px;
}
.gap-16{
  column-gap: 16px;
  row-gap: 16px;
}
.gap-24{
  column-gap: 24px;
  row-gap: 24px;
}

.ant-tooltip-placement-top {
  .ant-tooltip-arrow {
    bottom: 0;
  }
}

.ant-modal.ant-modal-confirm {
  .ant-modal-confirm-body{
    .anticon-exclamation-circle{
      * {
        fill: @dart-primary;
      }
    }
    .anticon-info-circle{
      * {
        fill: @dart-secondary;
      }
    }
  }
  &.ant-modal-confirm-info{
    .ant-modal-content {
      .ant-btn.ant-btn-primary {
        background-color: @dart-secondary;
      }
    }
  }
  .ant-modal-content {
    border-radius: 17px;
    .ant-btn.ant-btn-primary{
      font-family: 'GothamBold', sans-serif;
      font-weight: 800;
      text-transform: uppercase;
      border-radius: @dart-border-radius-sm;
      display: inline-block;
      column-gap: 8px;
      gap: 8px;
      justify-content: center;
      align-items: center;
      outline: none;
      border: none;
      height: 32px;
      font-size: 12px;
      line-height: 12px;
      padding: 4px 20px;
      background-color: @dart-primary;
      color: white;
    }
    .ant-btn.ant-btn-default{
      font-family: 'GothamBold', sans-serif;
      font-weight: 800;
      text-transform: uppercase;
      border-radius: @dart-border-radius-sm;
      display: inline-block;
      column-gap: 8px;
      gap: 8px;
      justify-content: center;
      align-items: center;
      outline: none;
      height: 32px;
      font-size: 12px;
      line-height: 12px;
      padding: 4px 20px;
      border: 1px solid @dart-primary;
      background-color: transparent;
      color: @dart-primary;
      &.ant-btn-dangerous{
        border: 1px solid @dart-danger;
        color: @dart-danger;
      }
    }
  }
}

.ant-form-item-explain{
  .ant-form-item-explain-error{
    display: none;
    &:nth-child(1){
      display: block;
    }
  }
}
.ant-form-item-has-error{
  .ant-picker-focused{
      box-shadow: 0 0 0 2px fade(@dart-danger, 20%) !important;
  }
  input{
    border-color: @dart-danger;
  }
}

.navigation-selector{
  position: absolute;
  right: 32px;
  top: 32px;
}

.resizable-cursor {
  cursor: ew-resize;
}

.ant-message-notice-content{
  background: @dart-dark;
  border-radius: 8px;
  font-family: 'GothamBold', sans-serif;
  font-weight: 450;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 21px 64px;
  width: 812px;
  max-width: 90%;
  position: relative;
  .anticon {
    position: absolute;
    left: 20px;
    top: 21px;
  }
}
.ant-modal-wrap{
  z-index: 2000;
}
.ant-modal-mask{
  z-index: 1500;
}
.ant-dropdown{
  z-index: 2001;
}
.ant-message{
  z-index: 2010;
}

.ant-menu-item:not(.ant-menu-item-disabled){
  span a:hover{
    color: #E87324;
  }
}

.ant-menu-item-selected:not(.ant-menu-item-disabled){
  span a{
    color: #E87324;
  }
}

.no-access{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-family: 'GothamBold', sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: #E87324;
}

.padding-0{
  padding: 0 !important;
}

.color-primary {
  color: @dart-primary;
}

.color-secondary {
  color: @dart-secondary;
}

.underlined{
  text-decoration: underline;
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";