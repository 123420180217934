.dart-page-title {
  color: @dart-dark;
  font-family: "GothamBold", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  min-height: 24px;
  position: relative;
  user-select: none;
  &.compact{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &.upper{
    text-transform: uppercase;
  }
  .anticon *{
    fill: @dart-dark
  }
  .center, .right, .left{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    gap: 10px;

    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    .dart-icon{
      margin-top: -4px;
    }
  }
  .left{
    font-family: "GothamRegular", sans-serif;
    font-weight: 450;
    font-size: 12px;
    line-height: 12px;
    column-gap: 8px;
    gap:8px;
    position: absolute;
    left:0;
    .back-button{
      margin-left: 64px;
      border: 0 !important;
      box-shadow: none;
    }
  }
  .right{
    position: absolute;
    right: 0;
    font-family: "GothamRegular", sans-serif;
    font-weight: 450;
    font-size: 12px;
    line-height: 12px;
    column-gap: 8px;
    gap:8px
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";