.manual-replenishment-review {
  .top-filters {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .left {
      .ant-select-selector{
        height: 48px;
        width: 280px !important;
        .ant-select-selection-item{
          display: flex;
          align-items: center;
        }
      }
    }
    .right {
      display: flex;
      column-gap: 12px;

      .ant-btn {
        border: none;
        display: flex;
        column-gap: 12px;
        box-shadow: none;
        padding-left: 6px;
        padding-right: 6px;
        color: black;
        height: 24px;
        align-items: center;
        margin-bottom: 12px;
      }
    }
  }

  // for scroll not to overflow
  > .bottom {
    max-height: calc(100vh - 502px);
    overflow: scroll;
    width: calc(100% + 20px);
  }
  .replenishment-review-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding-right: 20px;
  }

  .collapse-child-render{
    padding: 18px 18px 0 18px;
    background: #FAFAFA;
    .dart-editable-cell{
      cursor: pointer;
    }
    .BaseTable{
      background: #FAFAFA;
    }
    .BaseTable__header-row{
      background: transparent !important;
      border-bottom: none !important;
    }
    .BaseTable__header > div > .BaseTable__header-row:first-child > div:first-child{
      background: #FAFAFA !important;
      border: none !important;
      border-bottom: 1px solid #E1E2E3 !important;
    }
    .BaseTable__row-cell{
      background: #FAFAFA;
      border-left: 1px solid #E1E2E3 !important;
      border-right: 1px solid #E1E2E3 !important;
      border-bottom: 1px solid #E1E2E3 !important;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: @dart-dark;
    }
    .dart-table .BaseTable__row:hover, .dart-table .BaseTable__row--hovered{
      background: #FAFAFA !important;
    }
    .dart-table .BaseTable__table-main .BaseTable__header{
      background: #FAFAFA;
    }
    .BaseTable__header-row,
    .BaseTable__row{
      background: #FAFAFA;
    }
    .BaseTable__header-cell{
      border: 1px solid #E1E2E3 !important;
      background: white !important;
      .BaseTable__header-cell-text {
        color: @dart-dark !important;
        font-family: 'GothamBold', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .BaseTable__row--customized{
      .BaseTable__row-cell:first-child{
        background: #FAFAFA !important;
      }
    }

    .nested-table-replenishment-cc-cell{
      .anchor-title{
        text-align: center;
        width: 100%;
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        color: @dart-dark;
      }
    }
    .dart-table .BaseTable__header-row, .dart-table .BaseTable__row{
      border-bottom: none;
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";