.poa-dashboard-page {
  .dart-page-title{
    height: 72px;
    margin-top: 0;
    z-index: 1;
    .center{
      font-family: 'GothamRegular', sans-serif;
      font-weight: 450;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: @dart-dark;
    }
  }
  .variance-wrapper {
    width: 100%;
    //margin-top: 20px; //@TODO: temporarily removed
    // temp here
    margin-top: -120px;
    display: flex;
    justify-content: flex-start;
    column-gap: 32px;
    .dart-select{
      display: flex;
      column-gap: 8px;
      align-items: center;
      .ant-select-arrow{
        top: 15px;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
        line-height: 33px;
      }
    }
    // till here
    .dart-input {
      display: flex;
      align-items: center;
      column-gap: 16px;
      label {
        width: fit-content;
        white-space: nowrap;
        padding-left: 0;
      }
      input {
        max-width: 60px;
        min-width: 60px;
        text-align: center;
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
  .poa-overview-widget{
    max-height: calc(100vh - 269px);
    overflow: scroll;
  }
}

// used for top header
.main-layout-content {
  .poa-dashboard-page {
    padding-top: 128px;
    .poa-overview-widget{
      max-height: unset;
      overflow: initial;
    }
    .container-content{
      padding: 0 64px;
    }
    .dart-page-title{
      background-color: @dart-light;
      position: fixed;
      width: 100vw;
      top: 94px;
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";