.replenish-cc-details {
  background: #FAFAFA;

  .table-wrapper {
    padding: 0 16px 0 16px;

    .BaseTable {
      background: #FAFAFA;
    }

    .dart-table .dart-table-expand-icon {
      padding-right: 20px;
      right: 0;
    }
  }

  .replenish-cc-details-cell-parent {
    border-left: 1px solid #E1E2E3;
    border-right: 1px solid #E1E2E3;
    background: @dart-light;

    &.action-cell {
      .dart-table-expand-icon {
        width: 144px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
      }
    }

    .dart-editable-cell {
      cursor: pointer;
    }

    &.white-background {
      background: white;
    }

    &.store-details {
      padding-right: 16px;
      .table-row-menu{
        right: 4px;
      }
      .replenish-cc-details-cell {
        width: 100%;
      }
    }
  }

  .replenish-cc-details-cell-parent.with-sub-cells {
    padding: 0 !important;
  }

  .replenish-cc-details-cell {
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;

    .left {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 8px;

      .store-name {
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      span {
        font-family: 'GothamLight', sans-serif;

        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        color: @dart-dark;

        &.vertical-splitter {
          height: 16px;
          width: 1px;
          background-color: @dart-gray;
          display: block;
        }

        &.store-status {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }

    &.with-sub-cells {
      width: 100%;
    }

    .sub-cells {
      display: flex;
      column-gap: 0;

      .sub-cell {
        min-width: 53px;
        height: 52px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid @dart-gray;
        flex: 1;

        &:last-child {
          border-right: 1px solid transparent;
        }

        > span:first-child {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: @dart-mid-gray;
        }

        > span:last-child {
          font-family: 'GothamLight', sans-serif;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: @dart-dark;
        }
      }
    }
  }

  .child-row-renderer {
    padding: 18px 18px 0 18px;
    background: #FAFAFA;

    .BaseTable__table{
      background: #FAFAFA;
    }

    .BaseTable__header,
    .BaseTable__header-row,
    .BaseTable__header > div > .BaseTable__header-row:first-child > div {
      height: 50px !important;
    }

    .BaseTable__row:first-child {
      border-top: none;
    }

    .dart-editable-cell {
      cursor: pointer;
    }

    .BaseTable {
      background: #FAFAFA;
    }

    .BaseTable__header-row {
      background: transparent !important;
      border-bottom: none !important;
    }

    .BaseTable__header > div > .BaseTable__header-row:first-child > div:first-child {
      background: #FAFAFA !important;
      border: none !important;
      border-bottom: 1px solid #E1E2E3 !important;
    }

    .BaseTable__row-cell {
      background: #FAFAFA;
      border-left: 1px solid #E1E2E3 !important;
      border-right: 1px solid #E1E2E3 !important;
      border-bottom: 1px solid #E1E2E3 !important;
      font-family: 'GothamLight', sans-serif;
      font-weight: 325;
      font-size: 12px;
      line-height: 18px;
      color: @dart-dark;
    }

    .dart-table .BaseTable__row:hover, .dart-table .BaseTable__row--hovered {
      background: #FAFAFA !important;
    }

    .dart-table .BaseTable__table-main .BaseTable__header {
      background: #FAFAFA;
    }

    .BaseTable__header-row,
    .BaseTable__row {
      background: #FAFAFA;
    }

    .BaseTable__header-cell {
      border: 1px solid #E1E2E3 !important;
      background: white !important;

      .BaseTable__header-cell-text {
        color: @dart-dark !important;
        font-family: 'GothamBold', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    .BaseTable__row--customized {
      .BaseTable__row-cell:first-child {
        background: #FAFAFA !important;
      }

      &:nth-child(2) {
        .BaseTable__row-cell:not(:first-child) {
          background: white !important;
        }
      }
    }

    .nested-table-replenishment-cc-cell {
      .anchor-title {
        text-align: center;
        width: 100%;
        font-family: 'GothamLight', sans-serif;
        font-weight: 325;
        font-size: 12px;
        line-height: 18px;
        color: @dart-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        &.hasError{
          background-color: fade(@dart-danger, 20);
        }
      }
    }

    .dart-table .BaseTable__header-row, .dart-table .BaseTable__row {
      border-bottom: none;
    }
  }
}

.exclude-store-action-dropdown{
  span.anticon{
    margin-top: -4px;
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";