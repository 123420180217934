.replenishment-start-block {
  padding: 14px;
  overflow: hidden;
  border-radius: 16px;
  border: 2px solid #F6F6F6;
  box-sizing: border-box;
  background-color: #F6F6F6;
  transition: 0.2s;

  .icon-wrapper {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 24px;

    .dart-icon * {
      fill: @dart-primary;
    }
  }

  h2 {
    font-family: 'GothamRegular', sans-serif;
    font-weight: 450;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: @dart-dark;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  p {
    font-family: 'GothamLight', sans-serif;
    font-weight: 325;
    font-size: 12px;
    line-height: 18px;
    color: @dart-dark;
    margin-top: 8px;
    margin-bottom: 0;
  }

  &.disabled {
    .icon-wrapper {
      background-color: #eeeeee;

      .dart-icon * {
        fill: #FFFFFF;
      }
    }

    h2 {
      color: #cecece;
    }

    p {
      color: #cecece;
    }
  }

  &:not(.disabled) {
    &:hover {
      border: 2px solid @dart-primary;
      transition: 0.2s;
    }
  }
}

@hack: true;@import "/codebuild/output/src2147881804/src/src/configs/variables.less";